import React, { useEffect, useRef } from 'react';
import { Col } from 'react-bootstrap';
import './Paging.css';

export function PagingInfinite({
                                   title, 
                                   content, 
                                   pageSize, 
                                   pageNumber,
                                   totalResults, 
                                   numberOfPages, 
                                   onChange, 
                                   fetching,
                                   loadMore,
                       }) {
    const tableRef = useRef(null);

    useEffect(() => {
        if (tableRef.current && loadMore) {
            const observer = new IntersectionObserver(
                (entries) => {
                    const [entry] = entries;
                    if (entry.isIntersecting && pageNumber < numberOfPages && !fetching) {
                        onChange(pageNumber + 1);
                    }
                },
                { threshold: 1 }
            );
            observer.observe(tableRef.current);
            return () => observer.disconnect();
        }
    }, [tableRef, pageNumber, numberOfPages, fetching, loadMore, onChange]);

    const itemsLoaded = Math.min(pageNumber * pageSize, totalResults);
    const itemsRemaining = Math.max(totalResults - itemsLoaded, 0);

    return (
        <Col md={12} lg={9}>
            <div className="content">
                <h2>{title}</h2>
                {content}
                <div className="paginatin-custom"></div>
                {loadMore && pageNumber < numberOfPages && (
                    <div ref={tableRef}>
                        {fetching ? <p>Loading more data...</p> : null}
                    </div>
                )}
            </div>
        </Col>
    );
}
