import React from 'react';
import Moment from 'react-moment';

const JourneyTable = ({ journeys, handleTableRowClick, activeJourney, speedUnit }) => {
    if (journeys.length === 0) {
        return <p>No journeys reported for unit.</p>;
    }

    return (
        <div className="table-responsive">
            <table className="table table-striped table-dark">
                <thead>
                <tr>
                    <th>When</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Duration</th>
                    <th>Distance</th>
                </tr>
                </thead>
                <tbody>
                {journeys.map((journey) => (
                    <tr
                        key={journey.journeyId}
                        onClick={() => handleTableRowClick(journey.journeyId)}
                        className={activeJourney === journey.journeyId ? 'active' : ''}
                    >
                        <td>
                            <Moment fromNow>{journey.startDateTime}</Moment>
                        </td>
                        <td>
                            <Moment format="DD-MM-YYYY">{journey.startDateTime}</Moment>
                        </td>
                        <td>
                            <Moment format="HH:mm:ss">{journey.startDateTime}</Moment>
                            <br />
                            <Moment format="HH:mm:ss">{journey.endDateTime}</Moment>
                        </td>
                        <td>{journey.duration.toFixed(0)} mins</td>
                        <td>
                            {speedUnit === 0
                                ? `${(journey.distance * 0.621371).toFixed(2)} miles`
                                : `${journey.distance.toFixed(2)} kms`}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default JourneyTable;
