import React from 'react';
import { FaArrowCircleLeft } from 'react-icons/fa';
import './BackButtonLogoHolder.css';
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';

function BackButtonLogoHolder(props) {
    const { backlink } = props;
    const defaultLink = "/";

    return (
        <div className="left backbuttonlogoholder">
            <Link to={backlink || defaultLink}>
                <Button variant={"secondary"}>
                    <FaArrowCircleLeft color="white" /> Back
                </Button>
            </Link>
        </div>
    );
}

export default BackButtonLogoHolder;
