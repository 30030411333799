import React, {useEffect, useState} from 'react';
import {useSortBy, useTable} from 'react-table';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import Loading from "../Loading";
import './VehicleList.css';
import Moment from "react-moment";
import {useNavigate} from "react-router";
import {PagingInfiniteLarge} from "../Helpers/PagingInfiniteLarge";
export const VehicleList = ({ userAccessToken, userEmail }) => {
    document.title = "AGMT | Vehicles";
    const [VehicleData, setVehicleData] = useState([]);
    const [, setLoading] = useState(true);
    const [, setError] = useState(false);
    const [pageSize, setPageSize] = useState(30);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [fetching, setFetching] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [queryParam, setQueryParam] = useState('');
    const [myVehiclesClicked, setMyVehiclesClicked] = useState(false);
    const [showAccountReference, setShowAccountReference] = useState(
        localStorage.getItem('showAccountReference') === 'true'
    );
    const [isMyVehiclesActive, setIsMyVehiclesActive] = useState(false);

    const navigate = useNavigate();

    const columns = React.useMemo(
        () => [
            ...(showAccountReference ? [{Header: 'Account', accessor: 'accountReference'}] : []),
            {Header: 'Vehicle', accessor: 'vehicleReference'},
            {Header: 'Device', accessor: 'deviceReference'},
            {
                Header: 'Provisioned',
                accessor: 'createdDateTime',
                Cell: ({value}) => <Moment fromNow>{value}</Moment>
            },
            {
                Header: 'Last Upload',
                accessor: 'lastPacketReceived',
                Cell: ({value}) => <Moment fromNow>{value}</Moment>
            },
        ],
        [showAccountReference]
    );

    useEffect(() => {
        localStorage.setItem('showAccountReference', showAccountReference.toString());
    }, [showAccountReference]);



    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
        columns,
        data: VehicleData
    }, useSortBy)

    useEffect(() => {
        getVehicles(pageSize, pageNumber, queryParam);
    }, [pageSize, pageNumber, queryParam]);

    const getVehicles = async (pageSize, pageNumber, queryParam) => {
        setFetching(true);

        try {
            const response = await fetch(`/api/Vehicle/GetVehicles?PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}&${queryParam}&GetDeviceManagerData=false`, GetHttpConfig(userAccessToken));
            const data = await response.json();

            setVehicleData(prevData => [...prevData, ...data.results]);
            setLoading(false);
            setError(false);
            setTotalResults(data.pageDetail.totalResults);
            setNumberOfPages(data.pageDetail.numberOfPages);
            setPageNumber(data.pageDetail.pageNumber);
        } catch (error) {
            console.error('Fetch error:', error);
            setVehicleData([]);
            setLoading(false);
            setError(true);
            setTotalResults(0);
            setNumberOfPages(1);
            setPageNumber(1);
        } finally {
            setFetching(false);
        }
    };


    const showMyVehicles = () => {
        const newQueryParam = `accountReference=${encodeURIComponent(userEmail)}`;
        setVehicleData([]);
        setLoading(true);
        setTotalResults(null);
        setNumberOfPages(null);
        setPageNumber(1);
        setQueryParam(newQueryParam);
        setMyVehiclesClicked(true);
        setIsMyVehiclesActive(true);
    }

    const exitMyVehicles = () => {
        const newQueryParam = ``;
        setVehicleData([]);
        setLoading(true);
        setTotalResults(null);
        setNumberOfPages(null);
        setPageNumber(1);
        setQueryParam(newQueryParam);
        setMyVehiclesClicked(true);
        setIsMyVehiclesActive(false);
    }

    useEffect(() => {
        if (myVehiclesClicked) {
            setMyVehiclesClicked(false);
        }
    }, [myVehiclesClicked]);


    const searchVehicles = event => {
        event.preventDefault();
        setVehicleData([]);
        setLoading(true);
        setTotalResults(null);
        setNumberOfPages(null);
        setPageNumber(1);
        setQueryParam(`vehicleReference=${encodeURIComponent(searchQuery)}`);
    }

    const handleVehicleReferenceChange = (event) => {
        setSearchQuery(event.target.value);
    }
    
    const [hoverIndex, setHoverIndex] = useState(-1);


    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>Vehicles</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={2} className="fixed-col1">
                        <div className="myvehicles-button">
                            <Form onSubmit={searchVehicles}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control
                                        type="text"
                                        value={searchQuery}
                                        onChange={handleVehicleReferenceChange}
                                        placeholder="Search Vehicles"
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                    <Col md={12} lg={2} className="fixed-col1">
                        <div className="myvehicles-button">
                            <Button
                                variant={isMyVehiclesActive ? "danger" : "primary"}
                                type="button"
                                className="full-button"
                                onClick={() => isMyVehiclesActive ? exitMyVehicles() : showMyVehicles()}>
                                {isMyVehiclesActive ? 'Exit My Vehicles' : 'My Vehicles'}
                            </Button>
                        </div>
                    </Col>
                        <Col md={12} lg={2} className="fixed-col1">
                        <div className="myvehicles-button">
                            <Button
                                variant="primary"
                                type="button"
                                className="full-button"
                                onClick={() => setShowAccountReference(prev => !prev)}
                            >
                                {showAccountReference ? 'Hide Account' : 'Show Account'}
                            </Button>
                        </div>
                    </Col>
                    <PagingInfiniteLarge
                        content={
                            <div className="content-vehicle-list">
                                <div className="table-responsive">
                                    <table {...getTableProps()} className="table table-striped table-dark">
                                        <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        className="cell-padding">
                                                        {column.render('Header')}
                                                        <span>
                        {column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}
                    </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                        </thead>

                                        <tbody {...getTableBodyProps()}>
                                        {rows.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr
                                                    {...row.getRowProps()}
                                                    className={i === hoverIndex ? "stackable" : ""}
                                                    onClick={() => navigate('/deviceOverview/' + row.original.vehicleReference)}
                                                    onMouseOver={() => setHoverIndex(i)}
                                                    onMouseOut={() => setHoverIndex(-1)}
                                                    style={i === hoverIndex ? {
                                                        backgroundColor: "#094f7e",
                                                        cursor: 'pointer',
                                                    } : {}}
                                                >
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td {...cell.getCellProps()} className="stackable">
                                                                {cell.render('Cell')}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                        {fetching && (
                                            <tr>
                                                <td colSpan="100%">
                                                    <Loading/>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        }

                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        totalResults={totalResults}
                        numberOfPages={numberOfPages}
                        onChange={e => getVehicles(pageSize, e)}
                        pageSizeChange={e => getVehicles(e, 1)}
                        pageSizeSet={e => setPageSize(e)}
                        fetching={fetching}
                        loadMore={searchQuery === ''}
                    />
                </Row>
            </Container>
        </div>
    );
}
