import React from 'react';
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const JourneySearch = ({ startDate, endDate, setStartDate, setEndDate, searchForJourneys, clearSearch }) => {
    return (
        <div className="contentsearch">
            <h4>Search</h4>
            <Form.Group>
                <p>Start Date:</p>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    isClearable
                    showTimeSelect
                    dateFormat="Pp"
                />
                <p>End Date:</p>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    isClearable
                    showTimeSelect
                    dateFormat="Pp"
                />
            </Form.Group>
            <Button variant="primary" onClick={searchForJourneys}>
                Search
            </Button>{' '}
            <Button variant="secondary" onClick={clearSearch}>
                Clear
            </Button>
        </div>
    );
};

export default JourneySearch;
