import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import './Paging.css';

export class Paging extends Component {
    displayName = Paging.name;

    constructor(props) {
        super(props);
        this.state = {
            pageSize: this.props.pageSize
        };
    }

    onChangePerPage = e => {
        const newPageSize = parseFloat(e.target.value);
        this.props.pageSizeChange(newPageSize);
        this.props.pageSizeSet(newPageSize);
        this.setState({ pageSize: newPageSize });
    };

    render() {
        const shouldShowPagination = this.props.totalResults > 10;

        return (
            <Col md={12} lg={9}>
                <div className="content">
                    <h2>{this.props.title}</h2>
                    {this.props.content}

                    {shouldShowPagination && (
                        <React.Fragment>
                            <div className="pagination-custom">
                                <Pagination
                                    activePage={this.props.pageNumber}
                                    itemsCountPerPage={this.state.pageSize}
                                    totalItemsCount={this.props.totalResults}
                                    onChange={this.props.onChange}
                                />
                            </div>
                            <div className="pagination-custom">
                                <label htmlFor="table-page-size">Items per Page: &nbsp;</label>
                                <select value={this.props.pageSize} onChange={this.onChangePerPage} id="table-page-size" name="table-page-size">
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                </select>
                            </div>
                            <div className="pagination-custom">
                                <label>Total pages: {this.props.numberOfPages}</label>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </Col>
        );
    }
}
