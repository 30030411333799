import React, {useEffect, useState} from 'react';
import {useSortBy, useTable} from 'react-table';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import Loading from "../Loading";
import './VehicleList.css';
import Moment from "react-moment";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import {PagingInfiniteLarge} from "../Helpers/PagingInfiniteLarge";

export const VehicleManagement = ({userAccessToken, userEmail}) => {
    document.title = "AGMT | Vehicles";
    const [VehicleData, setVehicleData] = useState([]);
    const [, setLoading] = useState(true);
    const [, setError] = useState(false);
    const [pageSize, setPageSize] = useState(30);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [fetching, setFetching] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [queryParam, setQueryParam] = useState('');

    const removeVehicle = async (vehicleReference) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete vehicle ${vehicleReference}?`);
        if (!isConfirmed) return;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userAccessToken
            },
            body: JSON.stringify({vehicleReference})
        };

        try {
            const response = await fetch('/api/VehicleRemoval/RemoveVehicle', requestOptions);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.errorMessage || `HTTP error! Status: ${response.status}`);
            }

            toast.success('Vehicle removed successfully');
            setVehicleData(prevData => prevData.filter(v => v.vehicleReference !== vehicleReference));
            console.log('Vehicle removed successfully');
        } catch (error) {
            console.error('Error during vehicle removal:', error);
            toast.error(`Error during vehicle removal: ${error.message}`);
        }
    };


    const columns = React.useMemo(
        () => [
            {Header: 'Account', accessor: 'accountReference'},
            {Header: 'Vehicle', accessor: 'vehicleReference'},
            {Header: 'Device', accessor: 'deviceReference'},
            {Header: 'Device Type', accessor: 'deviceType'},
            {Header: 'Bluetooth Number', accessor: 'bluetoothNumber'},
            {Header: 'Firmware Version', accessor: 'firmwareVersion'},
            {
                Header: 'Provisioned',
                accessor: 'createdDateTime',
                Cell: ({value}) => <Moment fromNow>{value}</Moment>
            },
            {
                Header: 'Last Upload',
                accessor: 'lastPacketReceived',
                Cell: ({value}) => <Moment fromNow>{value}</Moment>
            },
            {
                Header: '',
                accessor: 'actions1',
                Cell: ({row}) => {
                    if (row.original.deviceType === 'IC7.O') {
                        return (
                            <div>
                                <Link to={`/move-device?deviceReference=${encodeURIComponent(row.original.deviceReference)}&accountReference=${encodeURIComponent(row.original.accountReference)}&vehicleReference=${encodeURIComponent(row.original.vehicleReference)}`}>
                                    <Button variant="outline-warning" size="sm">Change Driver</Button>
                                </Link>{' '}
                            </div>
                        );
                    }
                    return (
                        <div>
                            
                                <Button variant={"outline-dark"} disabled={true} size="sm">Change Driver</Button>
                        </div>
                    );
                }
            },
            {
                Header: '',
                accessor: 'actions',
                Cell: ({row}) => {
                    if (row.original.deviceType === 'IC7.O') {
                        return (
                            <div>
                                <Link to={`/switch-customer?deviceReference=${row.original.deviceReference}`}>
                                    <Button variant="outline-primary" size="sm">Switch Customer</Button>
                                </Link>{' '}
                            </div>
                        );
                    }
                    return (
                        <div>

                            <Button variant={"outline-dark"} disabled={true} size="sm">Switch Customer</Button>
                        </div>
                    );
                }
            },
            {
                Header: '',
                accessor: 'actions2',
                Cell: ({row}) => (
                    <div>
                        <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => removeVehicle(row.original.vehicleReference)}
                        >
                            Delete Device
                        </Button>
                    </div>
                )
            },
        ],
        []
    );


    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
        columns,
        data: VehicleData
    }, useSortBy)

    useEffect(() => {
        getVehicles(pageSize, pageNumber, queryParam);
    }, [pageSize, pageNumber, queryParam]);

    const getVehicles = async (pageSize, pageNumber, queryParam) => {
        setFetching(true);

        try {
            const response = await fetch(`/api/Vehicle/GetVehicles?PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}&${queryParam}&GetDeviceManagerData=true`, GetHttpConfig(userAccessToken));
            const data = await response.json();

            setVehicleData(prevData => [...prevData, ...data.results]);
            setLoading(false);
            setError(false);
            setTotalResults(data.pageDetail.totalResults);
            setNumberOfPages(data.pageDetail.numberOfPages);
            setPageNumber(data.pageDetail.pageNumber);
        } catch (error) {
            console.error('Fetch error:', error);
            setVehicleData([]);
            setLoading(false);
            setError(true);
            setTotalResults(0);
            setNumberOfPages(1);
            setPageNumber(1);
        } finally {
            setFetching(false);
        }
    };

    const searchVehicles = event => {
        event.preventDefault();
        setVehicleData([]);
        setLoading(true);
        setTotalResults(null);
        setNumberOfPages(null);
        setPageNumber(1);
        setQueryParam(`vehicleReference=${encodeURIComponent(searchQuery)}`);
    }

    const handleVehicleReferenceChange = (event) => {
        setSearchQuery(event.target.value);
    }

    const [hoverIndex, setHoverIndex] = useState(-1);

    return (
        <div className="webcontainer">
            <ToastContainer position="top-right" autoClose={5000}/>
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>Vehicle Management</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} lg={2} className="fixed-col1">
                        <div className="contentsearch">
                            <Form onSubmit={searchVehicles}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control
                                        type="text"
                                        value={searchQuery}
                                        onChange={handleVehicleReferenceChange}
                                        placeholder="Search Vehicles"
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                    <Col md={2} lg={2} className="fixed-col1">
                        <div className="myvehicles-button">
                            <Link to={`/register/registerappaccount`}>
                                <Button variant="info" className="full-button" size="">Register App Account</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col md={2} lg={2} className="fixed-col1">
                        <div className="myvehicles-button">
                            <Link to={`/register/registerdeviceIC6`}>
                                <Button variant="primary" className="full-button" size="">Register IC6</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col md={2} lg={2} className="fixed-col1">
                        <div className="myvehicles-button">
                            <Link to={`/register/registerdevice`}>
                                <Button variant="warning" className="full-button" size="">Register IC7</Button>
                            </Link>
                        </div>
                    </Col>
                    <PagingInfiniteLarge
                        content={
                            <div className="content-vehicle-list">
                                <div className="table-responsive">
                                    <table {...getTableProps()} className="table table-striped table-dark">
                                        <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        className="cell-padding">
                                                        {column.render('Header')}
                                                        <span>
                        {column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}
                    </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                        </thead>

                                        <tbody {...getTableBodyProps()}>
                                        {rows.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr
                                                    {...row.getRowProps()}
                                                    className={i === hoverIndex ? "stackable" : ""}
                                                >
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td {...cell.getCellProps()} className="stackable">
                                                                {cell.render('Cell')}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                        {fetching && (
                                            <tr>
                                                <td colSpan="100%">
                                                    <Loading/>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        }

                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        totalResults={totalResults}
                        numberOfPages={numberOfPages}
                        onChange={e => getVehicles(pageSize, e)}
                        pageSizeChange={e => getVehicles(e, 1)}
                        pageSizeSet={e => setPageSize(e)}
                        fetching={fetching}
                        loadMore={searchQuery === ''}
                    />
                </Row>
            </Container>
        </div>
    );
}
