import React, {useEffect, useState} from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import Loading from "../Loading";
import Moment from 'react-moment';
import './CrashReports.css';
import {PagingInfinite} from "../Helpers/PagingInfinite";
import {useNavigate} from 'react-router-dom';

const CrashReports = (props) => {
    const initialShowInvalidCrashes = localStorage.getItem('showInvalidCrashes') === 'true';

    const [state, setState] = useState({
        CrashesData: [],
        loading: true,
        error: false,
        pageSize: 15,
        pageNumber: 1,
        totalResults: 0,
        numberOfPages: 1,
        vehicleReference: '',
        hoveredRowIndex: -1,
        showInvalidCrashes: initialShowInvalidCrashes,
        fetching: false
    });
    
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "AGMT | Crash";
        getCrashes(state.pageSize, state.pageNumber);
    }, [state.showInvalidCrashes]);

    const searchCrashes = (event) => {
        setState(prevState => ({
            ...prevState,
            loading: true,
            totalResults: null,
            numberOfPages: null,
            pageNumber: null
        }));
        getCrashes(state.pageSize, 1);
        event.preventDefault();
    }

    useEffect(() => {
        localStorage.setItem('showInvalidCrashes', state.showInvalidCrashes);
    }, [state.showInvalidCrashes]);

    const getCrashes = (pageSize, pageNumber) => {
        setState(prevState => ({...prevState, fetching: true}));

        return fetch(`/api/Crash/GetCrashes?VehicleReference=${state.vehicleReference}&ShowInvalidCrashes=${state.showInvalidCrashes}&PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}`, GetHttpConfig(props.userAccessToken))
            .then(response => response.json())
            .then(data => {
                setState(prevState => ({
                    ...prevState,
                    CrashesData: pageNumber === 1 ? data.results : [...prevState.CrashesData, ...data.results],
                    loading: false,
                    error: false,
                    totalResults: data.pageDetail.totalResults,
                    numberOfPages: data.pageDetail.numberOfPages,
                    pageNumber: data.pageDetail.pageNumber,
                    vehicleReference: state.vehicleReference,
                    fetching: false
                }));
            })
            .catch(error => {
                console.error(error);
                setState(prevState => ({
                    ...prevState,
                    CrashesData: null,
                    loading: false,
                    error: true,
                    totalResults: 0,
                    numberOfPages: 1,
                    pageNumber: 1,
                    vehicleReference: '',
                    fetching: false,
                }));
            });
    }

    const handleVehicleReferenceChange = event => {
        setState({...state, vehicleReference: event.target.value});
    }

    const getSeverityDetails = (severity) => {
        if (severity < 1) return { text: "Negligible", color: "grey" };
        if (severity >= 1 && severity <= 15) return { text: "Minor", color: "yellow" };
        if (severity > 15 && severity <= 20) return { text: "Major", color: "orange" };
        if (severity > 20) return { text: "Severe", color: "red" };
        return { text: "Unknown", color: "grey" };
    }
    

    let content = null;
    if (state.loading) {
        content = <Loading/>;
    } else if (state.error) {
        content = <p>There was an error.</p>;
    } else if (state.CrashesData.length === 0) {
        content = (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh'
            }}>
                <p style={{
                    color: 'white',
                    fontSize: '2em'
                }}>No Data</p>
            </div>
        );
    } else {
        content = (
            <div className="chart-wrapper-2">
                <table className="table table-striped table-dark">
                    <thead>
                    <tr>
                        <th>Crash Id</th>
                        <th>Vehicle</th>
                        <th>Device</th>
                        <th>Received</th>
                        <th>Occured</th>
                        <th>Severity</th>
                        <th>Confidence</th>
                    </tr>
                    </thead>
                    <tbody>
                    {state.CrashesData.map(crash => {
                        const { text: severityText, color: severityColor } = getSeverityDetails(crash.severity);
                        const rowStyle = {
                            cursor: 'pointer',
                            backgroundColor: state.hoveredRowIndex === crash.crashId ? '#094f7e' : ''
                        };

                        return (
                            <tr
                                key={crash.crashId}
                                onClick={() => navigate(`/crash/${crash.vehicleReference}/${crash.crashId}`)}
                                onMouseEnter={() => setState(prevState => ({
                                    ...prevState,
                                    hoveredRowIndex: crash.crashId
                                }))}
                                onMouseLeave={() => setState(prevState => ({...prevState, hoveredRowIndex: null}))}
                                style={rowStyle}
                            >
                                <td>
                                    {crash.crashId}
                                </td>
                                <td>{crash.vehicleReference}</td>
                                <td>{crash.deviceReference}</td>
                                <td>
                                    <Moment format="DD/MM/YYYY HH:mm:ss">{crash.createdDateTime}</Moment> <br/> (<Moment className="" fromNow>{crash.createdDateTime}</Moment>)
                                </td>
                                <td>
                                    <Moment format="DD/MM/YYYY HH:mm:ss">{crash.crashDate}</Moment> <br/> (<Moment className="" fromNow>{crash.crashDate}</Moment>)
                                </td>
                                <td style={{color: severityColor}}>{severityText}</td>
                                <td>{(crash.confidence * 100).toFixed(0)}%</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }


    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3" style={{textAlign: 'center'}}>
                            <h4>Crashes</h4>
                        </div>
                    </Col>
                    <Col md={12} lg={3}>
                        <div className="info-wrapper">
                            <h5>Search</h5>
                            <Form onSubmit={searchCrashes}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control
                                        type="text"
                                        value={state.vehicleReference}
                                        onChange={handleVehicleReferenceChange}
                                        placeholder="Enter a Vehicle"
                                    />
                                </Form.Group>
                            </Form>
                        </div>

                        <div className="myvehicles-button">
                            <Button variant="primary" type="button" className="full-button"
                                onClick={() => {
                                    setState(prevState => ({
                                        ...prevState,
                                        showInvalidCrashes: !prevState.showInvalidCrashes,
                                        CrashesData: [],
                                        pageNumber: 1
                                    }));
                                }}
                            >
                                {state.showInvalidCrashes ? "Show Valid Crashes" : "Show Invalid Crashes"}
                            </Button>
                        </div>
                        
                        <div className="info-desc">
                            <div className="info-wrapper">
                                <h5>Severity</h5>
                                <table className="table table-striped table-dark">
                                    <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Example</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Minor</td>
                                        <td>Low impact</td>
                                    </tr>
                                    <tr>
                                        <td>Major</td>
                                        <td>High impact</td>
                                    </tr>
                                    <tr>
                                        <td>Severe</td>
                                        <td>Extremely high impact</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Col>
                    <PagingInfinite
                        content={
                            <div>
                                {content}
                                {state.fetching && <Loading/>}
                            </div>
                        }
                        pageSize={state.pageSize}
                        pageNumber={state.pageNumber}
                        totalResults={state.totalResults}
                        numberOfPages={state.numberOfPages}
                        onChange={e => getCrashes(state.pageSize, e)}
                        pageSizeChange={e => getCrashes(e, 1)}
                        pageSizeSet={e => setState({...state, pageSize: e})}
                        fetching={state.fetching}
                        loadMore={true}
                    />
                </Row>
            </Container>
        </div>
    );
}
export default CrashReports;