import React, {useCallback, useEffect, useState} from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {Paging} from '../Helpers/Paging';
import Loading from "../Loading";
import {Link, useLocation, useNavigate} from 'react-router-dom';

const VehicleGroup = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const urlParam = location.search.substring(1);
    const groupName = decodeURIComponent(urlParam).replace(/%20/g, " ");

    const [VehicleGroupsData, setVehicleGroupsData] = useState([]);
    const [vehicleReference, setVehicleReference] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);

    useEffect(() => {
        document.title = "UBI Vehicle Group Management";
        getVehicleGroups(pageSize, pageNumber, groupName);
    }, [pageSize, pageNumber, groupName]);

    const getVehicleGroups = useCallback(async (pageSize, pageNumber, groupName) => {
        try {
            const response = await fetch(`/api/GroupManagement/GetVehicleGroups?GroupName=${groupName}&VehicleReference=${vehicleReference}&PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}`, GetHttpConfig(props.userAccessToken));
            const data = await response.json();
            setVehicleGroupsData(data.results);
            setLoading(false);
            setError(false);
            setTotalResults(data.pageDetail.totalResults);
            setNumberOfPages(data.pageDetail.numberOfPages);
            setPageNumber(data.pageDetail.pageNumber);
        } catch (error) {
            setVehicleGroupsData([]);
            setLoading(false);
            setError(true);
            setTotalResults(0);
            setNumberOfPages(1);
            setPageNumber(1);
        }
    }, [vehicleReference, props.userAccessToken, groupName]);

    const searchGroups = (event) => {
        event.preventDefault();
        setLoading(true);
        setTotalResults(null);
        setNumberOfPages(null);
        setPageNumber(null);
        getVehicleGroups(pageSize, 1, groupName);
    };

    const clearSearch = () => {
        setVehicleReference('');
        getVehicleGroups(pageSize, pageNumber, groupName);
    };

    const handleVehicleReferenceChange = (event) => {
        setVehicleReference(event.target.value);
    };

    const handleDeleteVehicleGroup = async (vehicleGroupId, vehicleReference) => {
        if (window.confirm('Do you want to remove ' + vehicleReference + ' from this group?')) {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userAccessToken},
                body: JSON.stringify({vehicleGroupId})
            };
            await fetch('/api/GroupManagement/DeleteVehicleGroup', requestOptions);
            getVehicleGroups(pageSize, pageNumber, groupName);
        }
    };

    let content = null;
    if (loading) {
        content = <Loading/>;
    } else if (error) {
        content = <p>There was an error.</p>;
    } else if (VehicleGroupsData.length === 0) {
        content = <p>No vehicle groups found.</p>;
    } else {
        content = (
            <div className="content-vehicle-list">
                <table className="table table-striped table-dark">
                    <thead>
                    <tr>
                        <th>Vehicle Reference</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {VehicleGroupsData.map(vehicleGroup => (
                        <tr key={vehicleGroup.vehicleGroupId}>
                            <td>{vehicleGroup.vehicleReference}</td>
                            <td>
                                <Button variant="outline-danger" type="button"
                                        onClick={() => handleDeleteVehicleGroup(vehicleGroup.vehicleGroupId, vehicleGroup.vehicleReference)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>Vehicle Group Management: {groupName}</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={2}>
                        <div className="contentsearch mt-5">
                            <h5>Search</h5>
                            <Form onSubmit={searchGroups}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="text" value={vehicleReference}
                                                  onChange={handleVehicleReferenceChange}
                                                  placeholder="Enter a Vehicle"/>
                                </Form.Group>
                            </Form>
                            <Button variant="primary" type="submit" onClick={searchGroups}
                                    className="mr-3">Search</Button>
                            <Button variant="secondary" type="button" onClick={clearSearch}>Clear</Button>
                        </div>
                        <div className="myvehicles-button">
                            <Link to={"/admin/addvehiclegroup?" + groupName}>
                                <Button variant="success" className="full-button" size="">Add Vehicle to Group</Button>
                            </Link>
                        </div>
                    </Col>
                    <Paging
                        content={content}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        totalResults={totalResults}
                        numberOfPages={numberOfPages}
                        onChange={(e) => getVehicleGroups(pageSize, e, groupName)}
                        pageSizeChange={(e) => getVehicleGroups(e, 1, groupName)}
                    />
                </Row>
            </Container>
        </div>
    );
};

export default VehicleGroup;
