import React, { useState, useEffect, useCallback } from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import {Paging} from '../Helpers/Paging';
import Loading from "../Loading";
import { Link } from "react-router-dom";
import moment from 'moment';

const CompanyManagement = (props) => {
    document.title = "UBI Company Management";

    const [CompanyData, setCompanyData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [name, setName] = useState('');

    const getCompanies = useCallback(async (pageSize, pageNumber) => {
        try {
            const response = await fetch(`/api/CompanyManagement/getCompanies?Name=${name}&PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}`, GetHttpConfig(props.userAccessToken));
            const data = await response.json();
            setCompanyData(data.results);
            setLoading(false);
            setError(false);
            setTotalResults(data.pageDetail.totalResults);
            setNumberOfPages(data.pageDetail.numberOfPages);
            setPageNumber(data.pageDetail.pageNumber);
        } catch (error) {
            setCompanyData([]);
            setLoading(false);
            setError(true);
            setTotalResults(0);
            setNumberOfPages(1);
            setPageNumber(1);
        }
    }, [name, props.userAccessToken]);

    useEffect(() => {
        getCompanies(pageSize, pageNumber);
    }, [getCompanies, pageSize, pageNumber]);

    const searchCompanies = async (event) => {
        event.preventDefault();
        setLoading(true);
        setTotalResults(null);
        setNumberOfPages(null);
        setPageNumber(null);
        await getCompanies(pageSize, 1);
    };

    const clearSearch = () => {
        setName('');
        getCompanies(pageSize, pageNumber);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleDeleteCompany = async (companyName) => {
        if (window.confirm('Do you want to delete ' + companyName + '?')) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userAccessToken },
                body: JSON.stringify({ name: companyName })
            };
            await fetch('/api/CompanyManagement/DeleteCompany', requestOptions);
            getCompanies(pageSize, pageNumber);
        }
    };

    let content;
    if (loading) {
        content = <Loading />;
    } else if (error) {
        content = <p>There was an error.</p>;
    } else if (CompanyData.length === 0) {
        content = <p>No companies found.</p>;
    } else {
        content = (
            <div className="content-vehicle-list">
                <table className="table table-striped table-dark">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Subscription Start Date</th>
                        <th>Subscription End Date</th>
                        <th>Edit</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {CompanyData.map(company => (
                        <tr key={company.companyId}>
                            <td>{company.name}</td>
                            <td>{moment(company.dataAccessFromDate).format('LL')}</td>
                            <td>{moment(company.subscriptionToDate).format('LL')}</td>
                            <td><Link variant="secondary" className="btn btn-outline-warning" type="button" to={"/admin/editcompany?" + company.name}>Edit Company</Link></td>
                            <td><Button variant="" className="btn btn-outline-danger" type="button" onClick={() => handleDeleteCompany(company.name)}>Delete</Button></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>Company Management</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={2}>
                        <div className="contentsearch mt-5">
                            <h4>Search</h4>
                            <Form onSubmit={searchCompanies}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="text" value={name} onChange={handleNameChange} placeholder="Enter a Company"/>
                                </Form.Group>
                                <Button variant="primary" type="submit" className="mr-3">Search</Button>
                                <Button variant="secondary" type="button" onClick={clearSearch}>Clear</Button>
                            </Form>
                        </div>
                        <div className="myvehicles-button">
                            <Link to="/admin/addCompany">
                                <Button variant="success" className="full-button" size="">Add Company</Button>
                            </Link>
                        </div>
                    </Col>
                    <Paging
                        content={content}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        totalResults={totalResults}
                        numberOfPages={numberOfPages}
                        onChange={page => getCompanies(pageSize, page)}
                        pageSizeChange={size => getCompanies(size, 1)}
                    />
                </Row>
            </Container>
        </div>
    );
};

export default CompanyManagement;
