
const GetHttpConfig = (token) => {
    let config = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
    return config;
};

export default GetHttpConfig;