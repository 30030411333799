import React, { Component } from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import {Button, Col, Container, Row} from 'react-bootstrap';
import Loading from "../Loading";
import {Bar} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './CompanyOverview.css';
import { Chart, registerables } from 'chart.js';
import {
    createJourneyDistanceBar,
    createJourneyPercentagesBarChart,
    createSpeedingAverageLineChart
} from "../Charts/CommonCharts";
import {
    createActiveDevicesBar,
    createJourneyCountBar,
    createJourneyDurationTotalBar
} from "../Charts/CompanyOverviewCharts";
Chart.register(...registerables);
export class CompanyOverview extends Component {
    constructor(props) {
        super(props);

        document.title = "AGMT | Overview";

        this.state = {
            JourneyCountData: [],
            ActiveDevicesData: [],
            TotalDurationMonth: [],
            JourneyDistanceData: [],
            JourneyDurationPercentages: [],
            VehicleData: [],
            TopDistance: [],
            SpeedingAverage: [],
            TopDuration: [],
            TopSpeed: [],
            TopScore: [],
            loading: true,
            pageSize: 5,
            pageNumber: 1,
            vehicleReference: '',
            IsAverage: true,
            monthLimit: 1,
            deviceInfosLoading: false
        };
        this.toggleData = this.toggleData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.setState({ deviceInfosLoading: true });
        
        const promises = [
            this.fetchDataFromAPI('/api/DeviceAnalysis/JourneyCount', 'JourneyCountData'),
            this.fetchDataFromAPI('/api/DeviceAnalysis/ActiveDevicesTotal', 'ActiveDevicesData'),
            this.fetchDataFromAPI('api/DeviceAnalysis/JourneyDurationTotal', 'TotalDurationMonth'),
            this.fetchDataFromAPI('api/DeviceAnalysis/JourneyDurationPercentages', 'JourneyDurationPercentages'),
            this.fetchDataFromAPI('api/DeviceAnalysis/JourneyDistanceTotal', 'JourneyDistanceData'),
            this.fetchDataFromAPI('api/DeviceAnalysis/SpeedingAverageTotal', 'SpeedingAverageTotal'),
            this.fetchLeaderboardData(2, 'TopDistance'),
            this.fetchLeaderboardData(3, 'TopScore'),
            this.fetchLeaderboardData(13, 'TopSpeed'),
            this.fetchLeaderboardData(8, 'TopDuration')
        ];

        Promise.all(promises)
            .finally(() => {
                this.setState({ loading: false });
            });
    }
    
    miniFetchData(){
        const promises = [
        this.fetchLeaderboardData(2, 'TopDistance'),
            this.fetchLeaderboardData(3, 'TopScore'),
            this.fetchLeaderboardData(13, 'TopSpeed'),
            this.fetchLeaderboardData(8, 'TopDuration')
    ];

        Promise.all(promises)
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    fetchDataFromAPI(url, dataKey) {
        return fetch(url, GetHttpConfig(this.props.userAccessToken))
            .then(response => response.json())
            .then(data => {
                this.setState({ [dataKey]: data });
            })
            .catch(error => {
                console.error(error);
                this.setState({ [dataKey]: null });
            });
    }
    fetchLeaderboardData(analysisId, stateKey) {
        const monthLimit = this.state.monthLimit;
        return fetch(`/api/DeviceAnalysis/GetLeaderboardData?MonthLimit=${monthLimit}&AnalysisId=${analysisId}&Limit=1`, GetHttpConfig(this.props.userAccessToken))
            .then(response => response.json())
            .then(data => {
                this.setState({[stateKey]: data[0], error: false});
            })
            .catch(error => {
                console.error(error);
                this.setState({[stateKey]: null, error: true});
            });
    }

    toggleData = () => {
        this.setState(prevState => ({
            IsAverage: !prevState.IsAverage
        }));
    }

    toggleMonthLimit = () => {
        this.setState(prevState => ({
            monthLimit: prevState.monthLimit === 1 ? 12 : 1
        }), () => {
            this.miniFetchData();
        });
    }




    render() {
        const {speedUnit} = this.props
        const { loading, error, JourneyCountData, ActiveDevicesData, TopDistance, TopScore, TopSpeed, TopDuration, TotalDurationMonth, JourneyDurationPercentages, JourneyDistanceData, SpeedingAverageTotal } = this.state;
        let journeyCountContent, activeDevicesContent, durationTotalCount, crashTable, provisionedTable, deviceInfos, JourneyDurationPercentageContent, JourneyDistanceContent, SpeedingAverageTotalContent;
        const toggleActiveDevicesData = this.state.IsAverage ? null : ActiveDevicesData;
        
        if (loading || speedUnit === null) {
            journeyCountContent = activeDevicesContent = durationTotalCount = crashTable = provisionedTable = JourneyDistanceContent = JourneyDurationPercentageContent = SpeedingAverageTotalContent = deviceInfos =  <Loading />;
        } else if (error) {
            journeyCountContent = activeDevicesContent = durationTotalCount = crashTable = provisionedTable = JourneyDistanceContent = JourneyDurationPercentageContent = SpeedingAverageTotalContent= <p>There was an error.</p>;
        } else {
            const { journeyCountBar, journeyBarOptions } = createJourneyCountBar(JourneyCountData, toggleActiveDevicesData);
            const { activeDevicesBar, activeDevicesOptions } = createActiveDevicesBar(ActiveDevicesData);
            const { journeyDurationTotalBar, journeyDurationBarOptions} = createJourneyDurationTotalBar(TotalDurationMonth, toggleActiveDevicesData)
            const {
                journeyPercentagesBarChart,
                journeyPercentagesBarChartOptions
            } = createJourneyPercentagesBarChart(JourneyDurationPercentages, ActiveDevicesData);

            const {
                journeyDistanceBar,
                journeyDistanceOptions
            } = createJourneyDistanceBar(JourneyDistanceData, toggleActiveDevicesData, speedUnit);
            const {
                speedingLineChart,
                speedingLineChartOptions
            } = createSpeedingAverageLineChart(SpeedingAverageTotal, ActiveDevicesData);
            
            journeyCountContent = (
                <div>
                    <Bar data={journeyCountBar} options={journeyBarOptions} plugins={[ChartDataLabels]} />
                </div>
            );

            activeDevicesContent = (
                <div>
                    <Bar data={activeDevicesBar} options={activeDevicesOptions} plugins={[ChartDataLabels]} />
                </div>
            );

            durationTotalCount = (
                <div>
                    <Bar data={journeyDurationTotalBar} options={journeyDurationBarOptions} plugins={[ChartDataLabels]} />
                </div>
            );

            JourneyDurationPercentageContent = (
                <div>
                    <Bar data={journeyPercentagesBarChart} options={journeyPercentagesBarChartOptions} plugins={[ChartDataLabels]} />
                </div>
            );

            JourneyDistanceContent = (
                <div>
                    <Bar data={journeyDistanceBar} options={journeyDistanceOptions} plugins={[ChartDataLabels]} />
                </div>
            );

            SpeedingAverageTotalContent = (
                <div>
                    <Bar data={speedingLineChart} options={speedingLineChartOptions} plugins={[ChartDataLabels]} />
                </div>
            );
                
            
            deviceInfos = (
                <div className="webcontainer">
                    <Row>
                        <Col md={6} lg={2} className="chart-col">
                            <div className="chart-wrapper-company">
                                <h5> Active Vehicles </h5>
                                <h5> {ActiveDevicesData?.month0 || 'No Data'}</h5>
                            </div>
                        </Col>
                        <Col md={6} lg={2} className="chart-col">
                            <div className="chart-wrapper-company">
                                <h5> Total Journeys </h5>
                                <h5> {JourneyCountData?.month0 || 'No Data'}</h5>
                            </div>
                        </Col>
                        <Col md={6} lg={2} className="chart-col">
                            <div className="chart-wrapper-company">
                                <h5> Top Duration </h5>
                                {TopDuration ? (
                                    <>
                                        <p> {TopDuration.vehicleReference}</p>
                                        <h5>{Math.floor(TopDuration.leaderboardValue / 3600)}h {Math.floor((TopDuration.leaderboardValue % 3600) / 60)}m</h5>
                                    </>
                                ) : 'No Data'}
                            </div>
                        </Col>
                        <Col md={6} lg={2} className="chart-col">
                            <div className="chart-wrapper-company">
                                <h5> Top Distance</h5>
                                {TopDistance ? (
                                    <>
                                        <p> {TopDistance.vehicleReference}</p>
                                        <h5>
                                            {speedUnit === 0
                                                ? `${Math.round(TopDistance.leaderboardValue / 1.60934)} miles`
                                                : `${Math.round(TopDistance.leaderboardValue)} kms`}
                                        </h5>
                                    </>
                                ) : 'No Data'}
                            </div>
                        </Col>
                        <Col md={6} lg={2} className="chart-col">
                            <div className="chart-wrapper-company">
                                <h5> Top Overall Score </h5>
                                {TopScore ? (
                                    <>
                                        <p> {TopScore.vehicleReference}</p>
                                        <h5> {TopScore.leaderboardValue.toFixed(0)}</h5>
                                    </>
                                ) : 'No Data'}
                            </div>
                        </Col>
                        <Col md={6} lg={2} className="chart-col">
                            <div className="chart-wrapper-company">
                                <h5> Top Speed </h5>
                                {TopSpeed ? (
                                    <>
                                        <p> {TopSpeed.vehicleReference}</p>
                                        <h5>
                                            {speedUnit === 0
                                                ? `${Math.round(TopSpeed.leaderboardValue * 2.23694)} mph`
                                                : `${Math.round(TopSpeed.leaderboardValue * 3.6)} kph`
                                            }
                                        </h5>
                                    </>
                                ) : 'No Data'}
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }

        return (
            <div className="webcontainer">
                <Container fluid>
                    <Row>
                        <Col md={12} lg={12} className="fixed-col2">
                            <div className="contentvehicle3" style={{ marginTop: '10px' }}>
                                <h4>Overview</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} lg={4} className="fixed-col2">
                            <div className="contentvehicle3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                <Button onClick={this.toggleMonthLimit} style={{ marginLeft: '10px', visibility: 'hidden'}}>
                                    <span style={{ width: '60px', display: 'inline-block', textAlign: 'center'}}>
                                        {this.state.monthLimit === 1 ? 'Month' : 'Year'}
                                    </span>
                                </Button>
                                <h5 style={{ margin: '0 auto' }}>Monthly Overview</h5>
                                <div style={{ width: '90px' }}>
                                </div>
                            </div>
                        </Col>
                        <Col md={8} lg={8} className="fixed-col2">
                            <div className="contentvehicle3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                <h5 style={{ margin: '0 auto' }}>Top Performers</h5>
                                <Button onClick={this.toggleMonthLimit} style={{ marginLeft: '10px'}} variant={"outline-primary"}>
                                    <span style={{ width: '60px', display: 'inline-block', textAlign: 'center', marginTop: '-2px' }}>
                                        {this.state.monthLimit === 1 ? 'Month' : 'Year'}
                                    </span>
                                </Button>
                            </div>
                        </Col>
                    </Row>

                        {deviceInfos}
                    <Row>
                        <Col md={12} lg={12} className="fixed-col2">
                            <div className="contentvehicle3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                <Button onClick={this.toggleData} style={{ marginLeft: '10px'}} variant={"outline-primary"}>
                                    {this.state.IsAverage ? (
                                        <span style={{ width: '60px', display: 'inline-block', textAlign: 'center'}}>Total</span>
                                    ) : (
                                        <span style={{ width: '60px', display: 'inline-block', textAlign: 'center'}}>Average</span>
                                    )}
                                </Button>
                                <h5 style={{ margin: '0 auto' }}>Year Overview</h5>
                                <div style={{ width: '100px' }}>
                                </div>
                            </div>
                        </Col>
                    </Row>



                    <Row>
                        <Col md={4} lg={4} className="chart-col">
                            <div className="chart-wrapper-company-overview">
                                <p className="chart-title">Active Devices</p>
                                {activeDevicesContent}
                            </div>
                        </Col>
                        <Col md={4} lg={4} className="chart-col">
                            <div className="chart-wrapper-company-overview">
                                <p className="chart-title">Journeys</p>
                                {journeyCountContent}
                            </div>
                        </Col>
                        <Col md={4} lg={4} className="chart-col">
                            <div className="chart-wrapper-company-overview">
                                <p className="chart-title">Duration</p>
                                {durationTotalCount}
                            </div>
                        </Col>
                    </Row>
                    <Row>

                        <Col md={4} lg={4} className="chart-col">
                            <div className="chart-wrapper-company-overview">
                                <p className="chart-title">Speeding Percentage</p>
                                {SpeedingAverageTotalContent}
                            </div>
                        </Col>
                        <Col md={4} lg={4} className="chart-col">
                            <div className="chart-wrapper-company-overview">
                                <p className="chart-title">Driving Duration</p>
                                {JourneyDurationPercentageContent}
                            </div>
                        </Col>
                        <Col md={4} lg={4} className="chart-col">
                            <div className="chart-wrapper-company-overview">
                                <p className="chart-title">Distance</p>
                                {JourneyDistanceContent}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
